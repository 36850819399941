import { useDisclosure } from '@chakra-ui/react';
import { trackGA4ViewCart, trackAnalyticsCustomEvent } from '@ifixit/analytics';
import { getVariantIdFromVariantURI } from '@ifixit/helpers';
import { SentryError } from '@ifixit/sentry';
import { useCart } from '@ifixit/shopify-cart-sdk/hooks';
import { createContext, useMemo, useContext, useCallback } from 'react';
import type { PropsWithChildren } from 'react';

export type CartDrawerContext = ReturnType<typeof useDisclosure> & {};
const CartDrawerContext = createContext<CartDrawerContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
type CartDrawerProviderProps = PropsWithChildren<{}>;

export function CartDrawerProvider({ children }: CartDrawerProviderProps) {
   const disclosure = useDisclosure();

   const value = useMemo((): CartDrawerContext => disclosure, [disclosure]);

   return <CartDrawerContext.Provider value={value}>{children}</CartDrawerContext.Provider>;
}

export function useCartDrawer() {
   const context = useContext(CartDrawerContext);
   if (context === null) {
      throw new SentryError('useCartDrawer must be used within a CartDrawerProvider');
   }
   const onViewCart = useCallback(() => {
      trackAnalyticsCustomEvent({
         eventCategory: 'Mini Cart',
         eventAction: 'Btn "View Cart" - Click',
         eventName: `${window.location.origin}${window.location.pathname}`,
      });
      context.onClose();
   }, [context.onClose]);

   const cartQuery = useCart();
   // we only want to track the analytics when the cart drawer is opened by the user
   const onOpenWithAnalytics = useCallback(
      (event?: Event, autoOpened?: boolean) => {
         // Make sure the cart data is loaded and successful
         if (
            cartQuery.isSuccess &&
            cartQuery.data &&
            !autoOpened &&
            cartQuery.data.lineItems.length
         ) {
            trackGA4ViewCart({
               currency: cartQuery.data.lineItems[0].price.currencyCode,
               value: cartQuery.data.lineItems.reduce(
                  (acc: number, item) => acc + Number(item.price.amount) * item.quantity,
                  0
               ),
               items: cartQuery.data.lineItems.map(item => ({
                  item_id: item.itemcode,
                  item_name: item.name + ' ' + item.variantTitle,
                  item_variant: getVariantIdFromVariantURI(item.shopifyVariantId),
                  price: Number(item.price.amount),
                  quantity: item.quantity,
               })),
            });
         }
         context.onOpen();
      },
      [context.onOpen, cartQuery?.isSuccess, cartQuery?.data]
   );

   return { ...context, onOpen: onOpenWithAnalytics, onViewCart };
}
