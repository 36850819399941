import { VStack, type StackProps } from '@chakra-ui/react';
import { type AddToCartDescriptor } from '@ifixit/shopify-cart-sdk';
import { useAddToCart, useShopifyStorefrontClient } from '@ifixit/shopify-cart-sdk/hooks';
import { type ElementType } from 'react';
import { useGetUserPrice } from '../../commerce';
import type { CartImageComponent } from '../types';
import { CrossSellItem } from './cross-sell-item';
import type { CrossSellProduct } from './types';
import { LocaleCode } from '@ifixit/i18n';

export type { CrossSellProduct } from './types';

interface CrossSellProps {
   analytics: {
      descriptor: Extract<AddToCartDescriptor, 'Cart Drawer Cross Sell' | 'Cart Page Cross Sell'>;
   };
   crossSellItems: CrossSellProduct[];
   spacing: StackProps['spacing'];
   ImageComponent: CartImageComponent;
   linkComponent?: ElementType;
   localeCode: LocaleCode;
}

export function CrossSell({
   analytics,
   crossSellItems,
   spacing = '0',
   ImageComponent,
   linkComponent,
   localeCode,
}: CrossSellProps) {
   const { addToCart, enabled: addToCartEnabled } = useAddToCart();
   const { storeCode } = useShopifyStorefrontClient();

   const getUserPrice = useGetUserPrice({ storeCode });

   return (
      <VStack spacing={spacing} align="stretch">
         {crossSellItems.map(item => (
            <CrossSellItem
               key={item.itemcode}
               item={item}
               onAddToCart={addCrossSellProductToCart}
               ImageComponent={ImageComponent}
               isAddingToCart={isAddingItem(item)}
               isAddToCartDisabled={!addToCartEnabled}
               linkComponent={linkComponent}
               localeCode={localeCode}
            />
         ))}
      </VStack>
   );

   function addCrossSellProductToCart(item: CrossSellProduct) {
      const userPrice = getUserPrice(item);
      const lineItem = {
         name: item.name,
         variantTitle: 'New',
         itemcode: item.itemcode,
         shopifyVariantId: item.shopifyVariantId,
         quantity: 1,
         imageSrc: item.imageSrc,
         price: userPrice.price,
         compareAtPrice: userPrice.compareAtPrice,
         categories: item.categories,
         url: item.url,
         fulfiller: item.fulfiller,
         oemPartnership: item.oemPartnership,
      };
      addToCart.mutate({
         analytics: { descriptor: analytics.descriptor, localeCode },
         lines: [lineItem],
      });
   }

   function isAddingItem(item: CrossSellProduct) {
      const isItem = addToCart.variables?.lines[0]?.itemcode === item.itemcode;
      return addToCart.isPending && isItem;
   }
}
