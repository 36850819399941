import { Link, type LinkProps } from '@chakra-ui/react';

const localeToSupportURL = {
   'en-us': 'https://help.ifixit.com/article/136-email-support',
   'en-gb': 'https://eu-ifixit-support.helpscoutdocs.com/article/589-contact-us',
   'en-ca': 'https://ca-ifixit-support.helpscoutdocs.com/article/287-contact-us',
   'en-au': 'https://au-ifixit-support.helpscoutdocs.com/article/286-contact-us',
   'en-eu': 'https://eu-ifixit-support.helpscoutdocs.com/article/589-contact-us',
   'fr-fr': 'https://fr-ifixit-support.helpscoutdocs.com/article/459-nous-contacter',
   'de-de': 'https://de-ifixit-support.helpscoutdocs.com/category/319-kontakt',
};

const defaultSupportURL = 'https://help.ifixit.com/article/136-email-support';

export type SupportLinkProps = Omit<LinkProps, 'href'> & {
   localeCode: keyof typeof localeToSupportURL;
};

export function SupportLink({ children, localeCode, ...otherProps }: SupportLinkProps) {
   const supportURL = localeToSupportURL[localeCode] ?? defaultSupportURL;
   return (
      <Link href={supportURL} fontWeight="semibold" textDecoration="underline" {...otherProps}>
         {children}
      </Link>
   );
}
