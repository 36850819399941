import {
   Alert,
   Box,
   ButtonProps,
   Collapse,
   Flex,
   HStack,
   IconButton,
   Link,
   Text,
   VStack,
} from '@chakra-ui/react';
import { faCircleExclamation, faMinus, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { multiplyMoney } from '@ifixit/helpers';
import { FaIcon } from '@ifixit/icons';
import { type CartLineItem as LineItem } from '@ifixit/shopify-cart-sdk';
import { useUpdateLineItemQuantity } from '@ifixit/shopify-cart-sdk/hooks';
import type { ElementType } from 'react';
import { useEffect } from 'react';
import { ProductVariantPrice } from '../../commerce';
import type { CartImageComponent } from '../types';
import { CartLineItemImage } from './CartLineItemImage';
import { LocaleCode } from '@ifixit/i18n';

interface CartLineItemProps {
   ImageComponent: CartImageComponent;
   lineItem: LineItem;
   onRemove: (lineItem: LineItem) => void;
   removeDisabled: boolean;
   linkComponent?: ElementType;
   localeCode: LocaleCode;
   ariaDecrease: string;
   ariaIncrease: string;
   notEnoughInventory: string;
   outOfStock: string;
   unableToUpdateQuantity: string;
}

export function CartLineItem({
   ImageComponent,
   lineItem,
   onRemove,
   removeDisabled,
   linkComponent,
   localeCode,
   ariaDecrease,
   ariaIncrease,
   notEnoughInventory,
   outOfStock,
   unableToUpdateQuantity,
}: CartLineItemProps) {
   const { updateLineItemQuantity, enabled: updateLineItemQuantityEnabled } =
      useUpdateLineItemQuantity();

   useEffect(() => {
      if (updateLineItemQuantity.isError) {
         const id = setTimeout(() => {
            updateLineItemQuantity.reset();
         }, 3000);
         return () => clearTimeout(id);
      }
   }, [updateLineItemQuantity.isError, updateLineItemQuantity.reset]);

   const incrementQuantity = () => {
      updateLineItemQuantity.mutate({
         line: lineItem,
         quantityDelta: 1,
         analytics: { localeCode },
      });
   };

   const decrementQuantity = () => {
      updateLineItemQuantity.mutate({
         line: lineItem,
         quantityDelta: -1,
         analytics: { localeCode },
      });
   };

   const nextFontSizeSm = '14px'; // Bypasses root font size difference between NextJS and Carpenter
   const quantityButtonProps: ButtonProps = {
      variant: 'solid',
      bgColor: 'gray.200',
      size: 'xxs',
      boxSize: '20px',
      _hover: { bgColor: 'gray.300' },
   };

   return (
      <Flex
         direction="column"
         w="full"
         p="3"
         bgColor="white"
         data-testid={`cart-drawer-line-item-${lineItem.itemcode}`}
      >
         <Flex w="full" justify="space-between" align="flex-start">
            <HStack spacing="3" align="flex-start">
               <CartLineItemImage
                  ImageComponent={ImageComponent}
                  src={lineItem.imageSrc}
                  alt={lineItem.name}
               />
               <Box>
                  <VStack align="flex-start" fontSize={nextFontSizeSm}>
                     <Flex direction="column">
                        <Link
                           as={linkComponent}
                           href={lineItem.url}
                           isExternal
                           fontWeight="semibold"
                           borderRadius="sm"
                           overflowWrap="break-word"
                           localeCode={localeCode}
                        >
                           {lineItem.name}
                        </Link>
                        <Text color="gray.600" mt={1} mb={0} fontFamily="mono">
                           {lineItem.itemcode}
                        </Text>
                        <Text color="gray.600" my={2}>
                           {lineItem.variantTitle}
                        </Text>
                     </Flex>
                     <HStack gap={3}>
                        <IconButton
                           aria-label={ariaDecrease}
                           icon={<FaIcon icon={faMinus} h="4" color="gray.500" />}
                           isDisabled={!updateLineItemQuantityEnabled || lineItem.quantity <= 1}
                           onClick={decrementQuantity}
                           data-testid="cart-drawer-decrease-quantity"
                           {...quantityButtonProps}
                        />
                        <Text
                           color="gray.800"
                           fontSize="12px"
                           my="0"
                           data-testid="cart-drawer-quantity"
                           sx={{ fontVariantNumeric: 'tabular-nums' }}
                        >
                           {lineItem.quantity}
                        </Text>
                        <IconButton
                           aria-label={ariaIncrease}
                           icon={<FaIcon icon={faPlus} h="4" color="gray.500" />}
                           isDisabled={
                              !updateLineItemQuantityEnabled ||
                              (lineItem.quantityAvailable != null &&
                                 lineItem.quantity >= lineItem.quantityAvailable)
                           }
                           onClick={incrementQuantity}
                           data-testid="cart-drawer-increase-quantity"
                           {...quantityButtonProps}
                        />
                     </HStack>
                     <InventoryTooLowMessage
                        item={lineItem}
                        notEnoughInventory={notEnoughInventory}
                        outOfStock={outOfStock}
                     />
                     <Collapse in={updateLineItemQuantity.isError} animateOpacity>
                        <Alert status="error" bg="transparent" textColor="red.500" py="0" px="1">
                           <FaIcon icon={faCircleExclamation} h="4" mr="1.5" color="red.500" />
                           {unableToUpdateQuantity}
                        </Alert>
                     </Collapse>
                  </VStack>
               </Box>
            </HStack>
            <Box>
               <IconButton
                  variant="ghost"
                  _hover={{ bg: 'gray.100', color: 'gray.400' }}
                  aria-label={`Remove ${lineItem.name} from cart`}
                  _active={{ bg: 'gray.200' }}
                  color="gray.300"
                  icon={<FaIcon icon={faTrash} h="4" />}
                  size="xs"
                  isDisabled={removeDisabled}
                  onClick={() => onRemove(lineItem)}
                  data-testid="cart-drawer-remove-item"
               />
            </Box>
         </Flex>
         <Box alignSelf="flex-end">
            <ProductVariantPrice
               price={multiplyMoney(lineItem.price, lineItem.quantity)}
               compareAtPrice={
                  lineItem.compareAtPrice == null
                     ? null
                     : multiplyMoney(lineItem.compareAtPrice, lineItem.quantity)
               }
               direction="column-reverse"
               size="small"
               localeCode={localeCode}
            />
         </Box>
      </Flex>
   );
}

function InventoryTooLowMessage({
   item,
   notEnoughInventory,
   outOfStock,
}: { item: LineItem; notEnoughInventory: string; outOfStock: string }) {
   if (item.quantityAvailable == null || item.quantity <= item.quantityAvailable) {
      return null;
   }
   const message = item.quantityAvailable < 1 ? outOfStock : notEnoughInventory;
   return (
      <Text fontSize="sm" textColor="red.500">
         {message}
      </Text>
   );
}
