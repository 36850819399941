import { SentryError } from '@ifixit/sentry';
import { createContext, useContext } from 'react';
import type { RefObject } from 'react';

export type Context = {
   hiddenBar: {
      isOpen: boolean;
      open: () => void;
      close: () => void;
   };
   navigation: {
      isOpen: boolean;
      toggleButtonRef: RefObject<HTMLButtonElement | null>;
      toggle: () => void;
      close: () => void;
   };
};

export const HeaderContext = createContext<Context | null>(null);

export const useHeaderContext = () => {
   const context = useContext(HeaderContext);
   if (!context) {
      throw new SentryError('useHeaderContext must be used within a Header');
   }
   return context;
};
