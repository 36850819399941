import { Button, Link } from '@chakra-ui/react';
import { trackAnalyticsCustomEvent } from '@ifixit/analytics';
import { useTranslations } from '@ifixit/i18n';
import { useCart, useCheckout } from '@ifixit/shopify-cart-sdk/hooks';

export function ShopifyCheckoutButton() {
   const cart = useCart();
   const { checkoutUrl } = useCheckout();
   const t = useTranslations('CartPage');
   if (!cart.data?.hasItemsInCart || !checkoutUrl) {
      return null;
   }
   return (
      <Button
         as={Link}
         variant="cta"
         fontSize="16px"
         href={checkoutUrl}
         onClick={() => {
            trackAnalyticsCustomEvent({
               eventCategory: 'Mini Cart',
               eventAction: 'Btn "Check Out" - Click',
               eventName: `${window.location.origin}${window.location.pathname}`,
            });
         }}
         _hover={{
            bg: 'brand.600',
            textDecor: 'none',
         }}
         data-testid="checkout-button"
      >
         {t('checkout')}
      </Button>
   );
}
