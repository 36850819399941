import {
   Box,
   Divider,
   Flex,
   Skeleton,
   Text,
   Popover,
   PopoverArrow,
   PopoverBody,
   PopoverContent,
   PopoverTrigger,
   Progress,
} from '@chakra-ui/react';
import { type Cart } from '@ifixit/shopify-cart-sdk';
import { useCart } from '@ifixit/shopify-cart-sdk/hooks';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FaIcon } from '@ifixit/icons';
import { LocaleCode, useTranslations } from '@ifixit/i18n';
import { formatMoney, moneyToNumber } from '@ifixit/helpers';

const DefaultUSFreeShippingThreshold = 75;
const DefaultEUFreeShippingThreshold = 65;

function FreeShippingText({
   amountLeft,
   percent,
   note,
}: { amountLeft: string; percent: number; note?: string }) {
   const success = percent >= 100;
   const t = useTranslations('CartPage.freeShippingBanner');

   if (success) {
      return (
         <Flex alignItems="center">
            <Text color="brand.500" fontWeight="bold">
               {t('success')}
            </Text>
            <FreeShipTooltip note={note} />
         </Flex>
      );
   }

   return (
      <Flex alignItems="center">
         <Box>
            <Text color="brand.500">
               {t.rich('title', {
                  strong: chunks => <strong>{chunks}</strong>,
                  amountLeft,
               })}
            </Text>
         </Box>
         <FreeShipTooltip note={note} />
      </Flex>
   );
}

function CartDoesNotQualifyText({ note }: { note?: string }) {
   const t = useTranslations('CartPage.freeShippingBanner');
   return (
      <Flex alignItems="center">
         <Box>
            <Text color="brand.500">{t('doesNotQualify')}</Text>
         </Box>
         <FreeShipTooltip note={note} />
      </Flex>
   );
}

function FreeShipTooltip({ note }: { note?: string }) {
   if (!note) {
      return null;
   }
   return (
      <Popover trigger="hover" strategy="fixed">
         <PopoverTrigger>
            <FaIcon icon={faCircleExclamation} color="brand.500" ml="2" />
         </PopoverTrigger>
         <PopoverContent maxWidth="fit-content" border={0}>
            <PopoverArrow backgroundColor="gray.800" />
            <PopoverBody
               borderRadius="md"
               backgroundColor="gray.800"
               color="white"
               fontSize="13px"
               sx={{
                  '& a': {
                     color: 'white',
                     fontWeight: 'semibold',
                     textDecoration: 'underline',
                  },
               }}
            >
               <strong>{note}</strong>
            </PopoverBody>
         </PopoverContent>
      </Popover>
   );
}

const notes = new Map<string, string>([
   [
      'uk',
      'If your order includes a rechargeable battery, due to legal transport regulations we can only offer free shipping to the Main Island.',
   ],
   [
      'eu',
      'If your order includes a rechargeable battery, we can only offer free shipping to the following countries due to legal transport regulations within the EU: Germany, Austria, France, Italy, Belgium, the Netherlands and Spain (Mainland).',
   ],
   [
      'eu_test',
      'If your order includes a rechargeable battery, we can only offer free shipping to the following countries due to legal transport regulations within the EU: Germany, Austria, France, Italy, Belgium, the Netherlands and Spain (Mainland).',
   ],
   [
      'fr',
      'Si votre colis comprend une batterie rechargeable, la réglementation des transports ne nous permet d’offrir la livraison uniquement en France, Allemagne, Autriche, Italie, Belgique, Pays-Bas et Espagne (continentale).',
   ],
   [
      'de',
      'Sollte deine Bestellung einen Akku beinhalten, können wir aufgrund von gesetzlichen Transportbestimmungen innerhalb der EU den kostenlosen Versand nur in folgende Länder anbieten: Deutschland, Österreich, Frankreich, Belgien, Niederlande, Italien und Spanien (Festland).',
   ],
   [
      'test',
      'Free shipping is available to the contiguous US only. Partner-fulfilled items do not qualify for free shipping.',
   ],
   [
      'us',
      'Free shipping is available to the contiguous US only. Partner-fulfilled items do not qualify for free shipping.',
   ],
   [
      'dev',
      'Free shipping is available to the contiguous US only. Partner-fulfilled items do not qualify for free shipping.',
   ],
]);

function FreeShippingTextWithStoreNote({
   cartQualifies,
   storeCode,
   amountLeft,
   percent,
}: { cartQualifies: boolean; storeCode: string; amountLeft: string; percent: number }) {
   const note = notes.get(storeCode);
   if (!cartQualifies) {
      return <CartDoesNotQualifyText note={note} />;
   }
   return <FreeShippingText amountLeft={amountLeft} percent={percent} note={note} />;
}

const excludedFulfillers = new Set(['bti', 'encompass']);

function hasExcludedFulfiller(cart: Cart | undefined) {
   if (!cart) {
      return false;
   }

   return cart.lineItems.some(({ fulfiller }) => {
      if (!fulfiller) {
         return false;
      }
      return excludedFulfillers.has(fulfiller.toLowerCase());
   });
}

export function FreeShippingBanner({
   freeShippingThreshold,
   storeCode,
   localeCode,
   priceTier,
}: {
   freeShippingThreshold?: number;
   storeCode: string;
   localeCode: LocaleCode;
   priceTier: string | null;
}) {
   const cart = useCart();

   if (!cart.data) {
      return <Skeleton height="81px" />;
   }

   const isExcludedStore = storeCode === 'ca' || storeCode === 'au';

   if (isExcludedStore) {
      return;
   }

   if (priceTier !== null) {
      return;
   }

   const isUsStore = storeCode === 'us' || storeCode === 'test';
   const cartQualifies = !isUsStore || !hasExcludedFulfiller(cart.data);
   const defaultShippingThreshold = isUsStore
      ? DefaultUSFreeShippingThreshold
      : DefaultEUFreeShippingThreshold;

   return (
      <CartPurchaseBanner
         cart={cart.data}
         cartQualifies={cartQualifies}
         cartTotalThreshold={freeShippingThreshold || defaultShippingThreshold}
         localeCode={localeCode}
         storeCode={storeCode}
      />
   );
}

function CartPurchaseBanner({
   cart,
   cartQualifies,
   cartTotalThreshold,
   localeCode,
   storeCode,
}: {
   cart: Cart;
   cartQualifies: boolean;
   cartTotalThreshold: number;
   localeCode: LocaleCode;
   storeCode: string;
}) {
   const cartTotal = cart?.totals.price;
   const currentAmount = moneyToNumber(cartTotal);
   const amountLeft = cartTotalThreshold - currentAmount;

   const amountLeftAsStr = formatMoney(
      {
         amount: amountLeft,
         currencyCode: cartTotal.currencyCode,
      },
      localeCode
   );

   const noDecimalThreshold = formatMoney(
      {
         amount: cartTotalThreshold,
         currencyCode: cartTotal.currencyCode,
      },
      localeCode,
      {
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }
   );

   const percent = (currentAmount / cartTotalThreshold) * 100;

   return (
      <>
         <Box
            data-testid="cart-drawer-purchase-banner"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={1.5}
            fontSize="14px"
         >
            <FreeShippingTextWithStoreNote
               amountLeft={amountLeftAsStr}
               percent={percent}
               cartQualifies={cartQualifies}
               storeCode={storeCode}
            />
            {cartQualifies && (
               <Flex width="100%" alignItems="center" gap={3}>
                  <Progress
                     width="100%"
                     bg="brand.200"
                     value={percent}
                     size="sm"
                     colorScheme="brand"
                     borderRadius="full"
                     flexGrow={1}
                  />
                  <Text fontSize="14px" fontWeight="semibold">
                     {noDecimalThreshold}
                  </Text>
               </Flex>
            )}
         </Box>
         <Divider mx={-3} my={0} width="calc(100% + 24px)" />
      </>
   );
}
