import {
   Button,
   Flex,
   FlexProps,
   forwardRef,
   Input,
   InputGroup,
   InputGroupProps,
   InputLeftElement,
} from '@chakra-ui/react';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { useAppContext } from '@ifixit/app/hooks';
import { FaIcon } from '@ifixit/icons';
import { useTranslations } from '@ifixit/i18n';

export const DesktopHeaderSearchForm = forwardRef<FlexProps, 'form'>((props, ref) => {
   const appContext = useAppContext();
   return (
      <Flex
         ref={ref}
         as="form"
         method="GET"
         action={`${appContext.ifixitOrigin}/Search`}
         flexGrow={1}
         mx="8"
         display={{
            base: 'none',
            md: 'block',
         }}
         {...props}
      />
   );
});

export const MobileHeaderSearchForm = forwardRef<FlexProps, 'form'>((props, ref) => {
   const appContext = useAppContext();
   return (
      <Flex
         ref={ref}
         as="form"
         method="GET"
         action={`${appContext.ifixitOrigin}/Search`}
         flexGrow={1}
         mr="1"
         {...props}
      />
   );
});

export const HeaderSearchForm = {
   Desktop: DesktopHeaderSearchForm,
   Mobile: MobileHeaderSearchForm,
};

type SearchInputProps = InputGroupProps & { placeholder: string };

export const SearchInput = forwardRef<SearchInputProps, 'input'>(
   ({ placeholder, ...inputGroupProps }, ref) => {
      const t = useTranslations();
      return (
         <InputGroup {...inputGroupProps}>
            <InputLeftElement pointerEvents="none">
               <Button
                  variant="unstyled"
                  type="submit"
                  aria-label={t('Header.SearchForm.search')}
                  ml="8"
                  mb="-1px"
               >
                  <FaIcon icon={faMagnifyingGlass} h="4" color="gray.600" />
               </Button>
            </InputLeftElement>
            <Input
               ref={ref}
               name="query"
               aria-label={placeholder}
               placeholder={placeholder}
               _placeholder={{
                  color: 'gray.500',
               }}
               tabIndex={0}
               variant="filled"
               bg="gray.200"
               color="gray.700"
               fontSize="sm"
               borderRadius="full"
               _focus={{
                  bg: 'gray.100',
               }}
            />
         </InputGroup>
      );
   }
);
